import {
  BoxProps,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { SearchIcon } from '@chakra-ui/icons';
import SearchInput from './SearchInput';

type HeaderSearchButtonProps = BoxProps & {};

const HeaderSearchButton: React.FC<HeaderSearchButtonProps> = ({
  ...props
}) => {
  return (
    <Flex alignItems="center">
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <IconButton
            variant="unstyled"
            aria-label="Suche"
            icon={<SearchIcon />}
          />
        </PopoverTrigger>
        <PopoverContent
          minW={{
            base: 'none',
            md: '400px',
          }}
          color="gray.600"
        >
          <PopoverArrow />
          <PopoverBody>
            <SearchInput />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default observer(HeaderSearchButton);
