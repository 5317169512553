import { Box, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import PARAGRAPH_MAPPING from '../../config/paragraph-mapping';
import {
  ParagraphFromLibraryFragment,
  ParagraphPartnerListFragment,
} from '../../generated/types';
import Container from './Container';

interface FooterParagraphsProps {
  data: Array<ParagraphPartnerListFragment | ParagraphFromLibraryFragment>;
}

export const FooterParagraphs: React.FC<FooterParagraphsProps> = ({ data }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <Box>
      <Container>
        <VStack spacing={8} align="flex-start">
          {data?.map((data, i) => {
            if (PARAGRAPH_MAPPING[data.__typename]) {
              const Component = PARAGRAPH_MAPPING[data.__typename];
              return (
                <Component
                  key={`${data.id}_${i}`}
                  data={data}
                  isInArticleLayout
                />
              );
            }

            return null;
          })}
        </VStack>
      </Container>
    </Box>
  );
};
