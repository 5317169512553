import {
  Box,
  BoxProps,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';
import React, { ChangeEvent, FormEvent, useState } from 'react';

interface SearchInputProps {}

type SearchInputBoxProps = SearchInputProps & BoxProps;

const SearchInput: React.FC<SearchInputBoxProps> = ({ ...props }) => {
  const [query, setQuery] = useState('');
  const router = useRouter();

  const onSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (query === '') return;

    router.push(`/suche?query=${query}`);
  };

  const onChangeQuery = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
  };

  return (
    <Flex alignItems="center" flex={1} pos="relative" {...props}>
      <Box flex={1}>
        <form onSubmit={onSearch}>
          <FormControl>
            <FormLabel fontSize="sm">Freitextsuche</FormLabel>
            <Flex
              alignItems="center"
              flexDir={{
                base: 'column',
                md: 'row',
              }}
            >
              <InputGroup size="sm" marginRight={2} flex={1}>
                <InputLeftElement
                  zIndex={2}
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  onChange={onChangeQuery}
                  autoComplete="off"
                  name="query"
                  placeholder="Suche nach Hotel, Tour, Region..."
                />
              </InputGroup>
              <Button
                mt={{
                  base: 2,
                  md: 0,
                }}
                width={{
                  base: '100%',
                  md: 'auto',
                }}
                type="submit"
                size="sm"
                variant="outline"
                colorScheme="brand"
              >
                Suchen
              </Button>
            </Flex>
          </FormControl>
        </form>
      </Box>
    </Flex>
  );
};

export default SearchInput;
