import { Box, BoxProps, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { default as NextLink } from 'next/dist/client/link';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { isMenuItemActive } from '../../utils/helper';
import { MenuFragmentFragment, MenuItem } from '../../generated/types';
import { ChevronDownIcon } from '@chakra-ui/icons';

type HeaderMenuProps = BoxProps & {
  menu: MenuFragmentFragment;
  useInvisibleHeader?: boolean;
  activeBg?: string;
};

type HeaderMenuItemProps = {
  menu: MenuFragmentFragment;
  useInvisibleHeader?: boolean;
  activeBg?: string;
  item: MenuItem;
};

const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({
  menu,
  item,
  useInvisibleHeader,
  activeBg,
}) => {
  const router = useRouter();
  const [isHover, setIsHover] = useState(false);

  return (
    <Flex
      cursor="pointer"
      fontWeight="600"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      bg={
        isMenuItemActive(router.asPath, item.url)
          ? useInvisibleHeader
            ? 'transparent'
            : activeBg
          : 'transparent'
      }
      pos="relative"
      _hover={{
        bg: isMenuItemActive(router.asPath, item.url)
          ? useInvisibleHeader
            ? 'transparent'
            : activeBg
          : useInvisibleHeader
          ? 'transparent'
          : activeBg,
      }}
    >
      <Box as={NextLink} href={item.url} passHref>
        <Flex alignItems="center" as="a" paddingX={4}>
          {item.title}

          {item.children && item.children.length > 0 && (
            <ChevronDownIcon ml={2} fontSize="xl" />
          )}
        </Flex>
      </Box>
      {item.children && item.children.length > 0 && (
        <Box
          display={isHover ? 'block' : 'none'}
          className="ddn"
          pos="absolute"
          top="100%"
          left={0}
          bg="rgba(0,0,0, .8)"
          py={2}
          minWidth="160px"
          borderRadius="lg"
        >
          {item.children.map((child) => (
            <Box
              key={`${child.title}__${child.url}`}
              as={NextLink}
              href={child.url}
              passHref
            >
              <Flex
                alignItems="center"
                as="a"
                paddingX={4}
                py={2}
                whiteSpace="nowrap"
              >
                {child.title}
              </Flex>
            </Box>
          ))}
        </Box>
      )}
    </Flex>
  );
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({
  menu,
  useInvisibleHeader,
  activeBg = 'gray.100',
  ...props
}) => {
  return (
    <Flex alignItems="stretch" height="100%" {...props}>
      {menu
        ? menu.items!.map(
            (item) =>
              item && (
                <HeaderMenuItem
                  key={`${menu.name}__${item.title}`}
                  useInvisibleHeader={useInvisibleHeader}
                  activeBg={activeBg}
                  menu={menu}
                  item={item}
                />
              )
          )
        : null}
    </Flex>
  );
};

export default observer(HeaderMenu);
