import {
  ActiveFilter,
  Conjunction,
  EntityFilter,
  FilterInputGrouped,
} from '../generated/types';
import invert from 'lodash/invert';

const VALID_FILTER_FIELDS = [
  'city_ref_tid',
  'postcode_ref_tid',
  'country_tid',
  'region_tid',
  'feature_tid',
  'highlighted_feature_tid',
  'group_search',
];

const OPERATOR_MAP = {
  eq: '=',
  in: 'IN',
};

const FLIPPED_OPERATORS = invert(OPERATOR_MAP);

export function activeFilterToEntityFilter(filter: ActiveFilter) {
  return {
    field: filter.field,
    operator: '=',
    value: filter.value,
  };
}

export function parseGroupQueryString(
  query: string
): FilterInputGrouped | null {
  const splitted = query.split('||');

  // we should at least have one valid condition
  if (splitted.length < 2) {
    return null;
  }

  const conjunction = splitted[0];
  if (conjunction !== Conjunction.Or && conjunction !== Conjunction.And) {
    return null;
  }

  const conditionStrings = splitted.slice(1);
  const conditions = conditionStrings.map((string) =>
    parseFilterQueryString(string)
  );

  return {
    conjunction,
    conditions,
  };
}

export function filterInputGroupedToQueryString(filter: FilterInputGrouped) {
  const conditionString = filter.conditions
    .map((condition) => entityFilterConditionToQueryString(condition))
    .join('||');

  return `${filter.conjunction}||${conditionString}`;
}

export default function parseFilterQueryString(
  query: string
): EntityFilter | null {
  const splitted = query.split('|');

  if (splitted.length !== 3) return null;

  const operator =
    OPERATOR_MAP[splitted[1]] || OPERATOR_MAP[splitted[1].toLowerCase()];

  if (!operator) {
    return null;
  }

  const field = splitted[0];
  if (!VALID_FILTER_FIELDS.includes(field)) {
    return null;
  }

  return {
    field,
    operator,
    value: splitted[2],
  };
}

export function entityFilterConditionToQueryString(
  filter: EntityFilter
): string | null {
  const operator = FLIPPED_OPERATORS[filter.operator];

  if (!operator) {
    return null;
  }

  return `${filter.field}|${operator}|${
    Array.isArray(filter.value) ? filter.value.join(',') : filter.value
  }`;
}

export function filterFieldToHuman(field: string): string {
  switch (field) {
    case 'city_ref_tid':
      return 'Stadt';
    case 'region_tid':
      return 'Region';
    case 'postcode_ref_tid':
      return 'PLZ';
    case 'country_tid':
      return 'Land';

    default:
      return 'Filter';
  }
}
