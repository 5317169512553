import React from 'react';
import dynamic from 'next/dynamic';

const CUSTOM_COMPONENTS: {
  [index: string]: {
    [index: string]: React.ComponentType<any>;
  };
} = {
  defaults: {
    Header: dynamic(() => import('../components/Layout/Header')),
    Footer: dynamic(() => import('../components/Layout/Footer')),
    ActivityTeaser: dynamic(
      () => import('../components/Node/Activity/ActivityTeaser')
    ),
    HotelTeaser: dynamic(() => import('../components/Node/Hotel/HotelTeaser')),
    Hotel: dynamic(() => import('../components/Node/Hotel')),
    Activity: dynamic(() => import('../components/Node/Activity')),
    Poi: dynamic(() => import('../components/Node/Poi')),
  },
  newLayout: {
    Header: dynamic(() => import('../components/Layout/EbikeAtlasHeader')),
    Footer: dynamic(() => import('../components/Layout/EbikeAtlasFooter')),
    ActivityTeaser: dynamic(
      () => import('../components/Node/Activity/EbikeAtlasActivityTeaser')
    ),
    HotelTeaser: dynamic(
      () => import('../components/Node/Hotel/EbikeAtlasHotelTeaser')
    ),
    PoiTeaser: dynamic(
      () => import('../components/Node/Poi/EbikeAtlasPoiTeaser')
    ),
    Hotel: dynamic(
      () => import('../components/Node/Hotel/EbikeAtlasHotelIndex')
    ),
    Activity: dynamic(
      () => import('../components/Node/Activity/EbikeAtlasActivityIndex')
    ),
    Poi: dynamic(() => import('../components/Node/Poi/EbikeAtlasPoiIndex')),
  },
  ebikeatlas: {
    HotelContent: dynamic(
      () => import('../components/SiteSpecific/ebikeatlas/HotelContent')
    ),
    ActivityContent: dynamic(
      () => import('../components/SiteSpecific/ebikeatlas/ActivityContent')
    ),
  },
  bikerbetten: {
    HotelContent: dynamic(
      () => import('../components/SiteSpecific/bikerbetten/HotelContent')
    ),
    ActivityContent: dynamic(
      () => import('../components/SiteSpecific/bikerbetten/ActivityContent')
    ),
  },
};

export default CUSTOM_COMPONENTS;
