import { BoxProps, Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { HeaderProps, HeaderStyle } from './Header';
import {
  FulltextSearchQueryQuery,
  PageFragmentFragment,
  RouteQueryQuery,
  SearchByCategoryQueryQuery,
} from '../../generated/types';
import { useStore } from '../../store/provider';
import { observer } from 'mobx-react-lite';
import getAppComponent from '../../utils/getAppComponent';
import { FooterParagraphs } from './FooterParagraphs';

type LayoutProps = BoxProps & {
  data:
    | RouteQueryQuery
    | FulltextSearchQueryQuery
    | SearchByCategoryQueryQuery
    | undefined;
  noFooter?: Boolean;
};

const Layout: React.FC<LayoutProps> = ({ data, noFooter, ...props }) => {
  const store = useStore();

  const headerProps = useMemo<HeaderProps>(() => {
    let useInvisibleHeader = false;
    let useNegativeLogo = false;
    if (data) {
      if ('route' in data) {
        const routeQuery = data.route as RouteQueryQuery['route'];

        if (routeQuery?.entity?.__typename === 'Page') {
          const page = routeQuery.entity as PageFragmentFragment;

          if (
            page.content &&
            (page.content[0].__typename === 'ParagraphHero' ||
              page.content[0].__typename === 'ParagraphHeader')
          ) {
            useInvisibleHeader = true;

            const hero = page.content[0];
            useNegativeLogo = !!hero.useNegativeLogoVersion;
          }
        } else if (
          routeQuery?.entity?.__typename === 'Activity' ||
          routeQuery?.entity?.__typename === 'Hotel' ||
          routeQuery?.entity?.__typename === 'Poi'
        ) {
          if (store.config.usesNewLayout) {
            useInvisibleHeader = true;
            useNegativeLogo = true;
          }
        }
      }
    }

    store.setUseNegativeLogoVersion(useNegativeLogo);

    return {
      menu: data?.mainMenu,
      headerStyle: useInvisibleHeader
        ? HeaderStyle.invisible
        : HeaderStyle.visible,
    };
  }, [data]);

  const Header = getAppComponent(
    process.env.APP_ID!,
    store.config.usesNewLayout,
    'Header'
  );
  const Footer = getAppComponent(
    process.env.APP_ID!,
    store.config.usesNewLayout,
    'Footer'
  );

  const hasFooterParagraphs = 'footerParagraphs' in data;

  return (
    <Flex flex="1" flexDirection="column" {...props}>
      <Header {...headerProps} />

      {props.children}

      {hasFooterParagraphs && <FooterParagraphs data={data.footerParagraphs} />}

      {!noFooter && (
        <Footer
          menu={data?.footerMenu!}
          menuMain={(data as RouteQueryQuery)?.footerMenuMain}
        />
      )}
    </Flex>
  );
};

export default observer(Layout);
