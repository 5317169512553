import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { MenuFragmentFragment } from '../../generated/types';
import { default as NextLink } from 'next/link';
import { useStore } from '../../store/provider';

interface MobileMenuProps {
  onClose: () => void;
  isOpen: boolean;
  menu: MenuFragmentFragment;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose, menu }) => {
  const store = useStore();

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay zIndex={9999}>
        <DrawerContent pb="100px">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h1" fontSize={24} onClick={onClose}>
              <a>{store.config.siteName}</a>
            </Heading>
          </DrawerHeader>

          <DrawerBody>
            {menu && (
              <>
                {menu.items!.map(
                  (item) =>
                    item && (
                      <React.Fragment
                        key={`${menu.name}_mobile__${item.title}`}
                      >
                        <Flex
                          cursor="pointer"
                          alignItems="center"
                          fontWeight="600"
                          mr="8"
                          paddingY={2}
                        >
                          <NextLink href={item.url} passHref>
                            <a>{item.title}</a>
                          </NextLink>
                        </Flex>
                        {item.children &&
                          item.children.map((child) => (
                            <Flex
                              key={`${menu.name}_mobile__${child.title}`}
                              cursor="pointer"
                              alignItems="center"
                              fontWeight="600"
                              mr="8"
                              paddingY={2}
                              marginLeft={4}
                            >
                              <NextLink href={child.url} passHref>
                                <a>{child.title}</a>
                              </NextLink>
                            </Flex>
                          ))}
                      </React.Fragment>
                    )
                )}
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MobileMenu;
