import {
  Box,
  BoxProps,
  Flex,
  Heading,
  HeadingProps,
  Image,
  Img,
  Skeleton,
} from '@chakra-ui/react';
import Link from 'next/link';
import React, { useState } from 'react';
import { default as NextLink } from 'next/dist/client/link';
import getAppPublicPath from '../../utils/getAppPublicPath';
import { useRouter } from 'next/router';
import { useStore } from '../../store/provider';
import { observer } from 'mobx-react-lite';

type HeaderLogoProps = HeadingProps & {};

const SIZES = {
  bikeratlas: {
    width: '92px',
    height: '45px',
  },
  ebikeatlas: {
    width: '150px',
    height: '27px',
  },
  freizeithotels: {
    width: '184px',
    height: '45px',
  },
  bikerbetten: {
    width: '101px',
    height: '45px',
  },
};

const HeaderLogo: React.FC<HeaderLogoProps> = ({ ...props }) => {
  const router = useRouter();
  const isHome = router.asPath === '/';
  const store = useStore();

  const sizes = SIZES[process.env.APP_ID!];

  return (
    <Heading as={isHome ? 'h1' : 'h2'} fontSize={24} {...props}>
      <NextLink href="/" passHref>
        <a>
          <Img
            maxHeight="45px"
            src={`${getAppPublicPath()}/logo.svg`}
            htmlWidth={sizes?.width}
            htmlHeight={sizes?.height}
            alt={store.config.frontendName}
          />
        </a>
      </NextLink>
    </Heading>
  );
};

export default observer(HeaderLogo);
