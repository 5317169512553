import { BoxProps, Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { HamburgerIcon } from '@chakra-ui/icons';
import useIsMobile from '../../hooks/useIsMobile';

type HeaderBurgerButtonProps = BoxProps & {
  onClick: () => void;
};

const HeaderBurgerButton: React.FC<HeaderBurgerButtonProps> = ({ onClick }) => {
  const isMobile = useIsMobile();

  return (
    <Flex alignItems="stretch" hidden={!isMobile}>
      <IconButton
        onClick={onClick}
        variant="unstyled"
        aria-label="Menu"
        fontSize="2xl"
        icon={<HamburgerIcon />}
      />
    </Flex>
  );
};

export default observer(HeaderBurgerButton);
