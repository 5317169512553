import { Flex, FlexProps } from '@chakra-ui/react';
import React, { useState } from 'react';
import Container from './Container';
import { MenuFragmentFragment } from '../../generated/types';
import useIsMobile from '../../hooks/useIsMobile';
import MobileMenu from './MobileMenu';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@emotion/react';
import HeaderLogo from '../Common/HeaderLogo';
import HeaderMenu from '../Common/HeaderMenu';
import HeaderBurgerButton from '../Common/HeaderBurgerButton';
import HeaderSearchButton from '../Common/HeaderSearchButton';

export enum HeaderStyle {
  invisible,
  visible,
}

export type HeaderProps = FlexProps & {
  headerStyle?: HeaderStyle;
  menu: MenuFragmentFragment;
};

const Header: React.FC<HeaderProps> = ({
  children,
  headerStyle,
  menu,
  ...props
}) => {
  let useInvisibleHeader = headerStyle === HeaderStyle.invisible;
  const isMobile = useIsMobile();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const router = useRouter();
  const theme = useTheme() as any;

  const headerTheme: any | null = theme.custom?.header || null;

  return (
    <Flex
      bg={useInvisibleHeader ? 'transparent' : headerTheme?.bg || '#fff'}
      color={useInvisibleHeader ? '#fff' : headerTheme?.color || 'gray.800'}
      as="header"
      height="80px"
      alignItems="stretch"
      pos="relative"
      zIndex={8}
      flexDirection="column"
      boxShadow={useInvisibleHeader ? 'none' : 'rgba(0,0,0, .1) 0 2px 8px'}
      borderBottomWidth={useInvisibleHeader ? '1px' : 0}
      borderBottomColor={
        useInvisibleHeader ? 'rgba(255,255,255, .3)' : 'transparent'
      }
      {...props}
    >
      <MobileMenu
        menu={menu}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
      <Container height="100%">
        <Flex height="100%" justifyContent="space-between">
          <Flex alignItems="center">
            <HeaderLogo />
          </Flex>
          <HeaderBurgerButton onClick={() => setIsDrawerOpen(!isDrawerOpen)} />
          <Flex height="100%" hidden={isMobile} alignItems="stretch">
            <HeaderMenu
              menu={menu}
              useInvisibleHeader={useInvisibleHeader}
              activeBg={headerTheme?.menuActiveBg}
            />
            <HeaderSearchButton />
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default observer(Header);
