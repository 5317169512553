import { Box, Flex, Spinner } from '@chakra-ui/react';
import Container from '../Layout/Container';

export default function SiteLoading() {
    return (
        <Box>
            <Container>
                <Flex
                    flexDirection="column"
                    align="center"
                    justifyContent="center"
                    paddingY={8}
                >
                    <Box fontWeight="bold" fontSize="2xl" marginBottom={4}>
                        Lädt Daten
                    </Box>
                    <Spinner />
                </Flex>
            </Container>
        </Box>
    );
}
