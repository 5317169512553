import CUSTOM_COMPONENTS from '../config/custom-components';
import React from 'react';
import apps from '../config/apps';

export default function getAppComponent(
  appId: string,
  newLayout: boolean,
  componentName: string
) {
  let Component: null | React.ComponentType<any> = null;
  const fallbackKey = newLayout ? 'newLayout' : 'defaults';

  if (CUSTOM_COMPONENTS[appId] && CUSTOM_COMPONENTS[appId][componentName]) {
    Component = CUSTOM_COMPONENTS[appId][componentName];
  } else if (
    CUSTOM_COMPONENTS[fallbackKey] &&
    CUSTOM_COMPONENTS[fallbackKey][componentName]
  ) {
    Component = CUSTOM_COMPONENTS[fallbackKey][componentName];
  }

  return Component;
}

export function getAppComponentByAppId(appId: string, componentName: string) {
  if (apps[appId]) {
    return getAppComponent(
      appId,
      apps[appId].config.usesNewLayout,
      componentName
    );
  }

  return null;
}
