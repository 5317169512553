import React from 'react';
import dynamic from 'next/dynamic';

const PARAGRAPH_MAPPING: {
  [index: string]: React.ComponentType<any>;
} = {
  ParagraphFormular: dynamic(
    () => import('../components/Paragraph/Formular/Formular')
  ),
  ParagraphHero: dynamic(() => import('../components/Paragraph/Hero/Hero')),
  ParagraphHotelOffers: dynamic(
    () => import('../components/Paragraph/HotelOffers/HotelOffers')
  ),
  ParagraphActivityPoiTeaserList: dynamic(
    () =>
      import(
        '../components/Paragraph/ActivityPoiTeaserList/ActivityPoiTeaserList'
      )
  ),
  ParagraphActivitySlider: dynamic(
    () => import('../components/Paragraph/ActivitySlider/ActivitySlider')
  ),
  ParagraphText: dynamic(() => import('../components/Paragraph/Text/Text')),
  ParagraphActivityList: dynamic(
    () => import('../components/Paragraph/ActivityList/ActivityList')
  ),
  ParagraphFaq: dynamic(() => import('../components/Paragraph/Faq/Faq')),
  ParagraphHotelList: dynamic(
    () => import('../components/Paragraph/HotelList/HotelList')
  ),
  ParagraphActivityTabs: dynamic(
    () => import('../components/Paragraph/ActivityTabs/ActivityTabs')
  ),
  ParagraphRegionTeaserList: dynamic(
    () => import('../components/Paragraph/RegionTeaserList/RegionTeaserList')
  ),
  ParagraphMerchantList: dynamic(
    () => import('../components/Paragraph/MerchantList/MerchantList')
  ),
  ParagraphHeader: dynamic(
    () => import('../components/Paragraph/Header/Header')
  ),
  ParagraphSearchbar: dynamic(
    () => import('../components/Paragraph/Searchbar/Searchbar')
  ),
  ParagraphContainer: dynamic(
    () => import('../components/Paragraph/Container/Container')
  ),
  ParagraphColumns: dynamic(
    () => import('../components/Paragraph/Columns/Columns')
  ),
  ParagraphColumn: dynamic(
    () => import('../components/Paragraph/Column/Column')
  ),
  ParagraphImageTextTeaser: dynamic(
    () => import('../components/Paragraph/ImageTextTeaser/ImageTextTeaser')
  ),
  ParagraphTestimonial: dynamic(
    () => import('../components/Paragraph/Testimonial/Testimonial')
  ),
  ParagraphSearchResults: dynamic(
    () => import('../components/Paragraph/SearchResults/SearchResults')
  ),
  ParagraphImage: dynamic(() => import('../components/Paragraph/Image/Image')),
  ParagraphImageAndText: dynamic(
    () => import('../components/Paragraph/ImageAndText/ImageAndText')
  ),
  ParagraphNewsList: dynamic(
    () => import('../components/Paragraph/NewsList/NewsList')
  ),
  ParagraphProductList: dynamic(
    () => import('../components/Paragraph/ProductList/ProductList')
  ),
  ParagraphEyeCatcher: dynamic(
    () => import('../components/Paragraph/EyeCatcher/EyeCatcher')
  ),
  ParagraphLinks: dynamic(() => import('../components/Paragraph/Links/Links')),
  ParagraphInfolinoRating: dynamic(
    () => import('../components/Paragraph/InfolinoRating/InfolinoRating')
  ),
  ParagraphInfolinoContact: dynamic(
    () => import('../components/Paragraph/InfolinoContact/InfolinoContact')
  ),
  ParagraphInfolinoActivities: dynamic(
    () =>
      import('../components/Paragraph/InfolinoActivities/InfolinoActivities')
  ),
  ParagraphInfolinoPOIs: dynamic(
    () => import('../components/Paragraph/InfolinoPOIs/InfolinoPOIs')
  ),
  ParagraphSearchById: dynamic(
    () => import('../components/Paragraph/SearchById/SearchById')
  ),
  ParagraphInfolinoWellnessFormular: dynamic(
    () =>
      import(
        '../components/Paragraph/InfolinoWellnessFormular/InfolinoWellnessFormular'
      )
  ),
  ParagraphInfolinoFeedbackFormular: dynamic(
    () =>
      import(
        '../components/Paragraph/InfolinoFeedbackFormular/InfolinoFeedbackFormular'
      )
  ),
  ParagraphFromLibrary: dynamic(
    () => import('../components/Paragraph/FromLibrary/FromLibrary')
  ),
  ParagraphActivityPoiActivitiesInRegion: dynamic(
    () =>
      import(
        '../components/Paragraph/ActivityPoiActivitiesInRegion/ActivityPoiActivitiesInRegion'
      )
  ),
  ParagraphActivityPoiImages: dynamic(
    () => import('../components/Paragraph/ActivityPoiImages/ActivityPoiImages')
  ),
  ParagraphActivityPoiMap: dynamic(
    () => import('../components/Paragraph/ActivityPoiMap/ActivityPoiMap')
  ),
  ParagraphActivityPoiNearHotels: dynamic(
    () =>
      import(
        '../components/Paragraph/ActivityPoiNearHotels/ActivityPoiNearHotels'
      )
  ),
  ParagraphActivityPoiPoisInRegion: dynamic(
    () =>
      import(
        '../components/Paragraph/ActivityPoiPoisInRegion/ActivityPoiPoisInRegion'
      )
  ),
  ParagraphActivityPoiProductsInRegion: dynamic(
    () =>
      import(
        '../components/Paragraph/ActivityPoiProductsInRegion/ActivityPoiProductsInRegion'
      )
  ),
  ParagraphSidebarBlock: dynamic(
    () => import('../components/Paragraph/SidebarBlock/SidebarBlock')
  ),
  ParagraphCommentListAndFormular: dynamic(
    () =>
      import(
        '../components/Paragraph/CommentListAndFormular/CommentListAndFormular'
      )
  ),
  ParagraphActivityPoiNearPois: dynamic(
    () =>
      import('../components/Paragraph/ActivityPoiNearPois/ActivityPoiNearPois')
  ),
  ParagraphActivityPoiNearActivities: dynamic(
    () =>
      import(
        '../components/Paragraph/ActivityPoiNearActivities/ActivityPoiNearActivities'
      )
  ),
  ParagraphCustomTeaserList: dynamic(
    () => import('../components/Paragraph/CustomTeaserList/CustomTeaserList')
  ),
  ParagraphPoiPassProfile: dynamic(
    () => import('../components/Paragraph/PoiPassProfile/PoiPassProfile')
  ),
  ParagraphPassTable: dynamic(
    () => import('../components/Paragraph/PassTable/PassTable')
  ),
  ParagraphPassMap: dynamic(
    () => import('../components/Paragraph/PassMap/PassMap')
  ),
  ParagraphPassListMap: dynamic(
    () => import('../components/Paragraph/PassListMap/PassListMap')
  ),
  ParagraphAuthors: dynamic(
    () => import('../components/Paragraph/Authors/Authors')
  ),
  ParagraphPartner: dynamic(
    () => import('../components/Paragraph/Partner/Partner')
  ),
  ParagraphCommentBox: dynamic(
    () => import('../components/Paragraph/CommentBox/CommentBox')
  ),
  ParagraphInfoBox: dynamic(
    () => import('../components/Paragraph/InfoBox/InfoBox')
  ),
  ParagraphAdSlot: dynamic(
    () => import('../components/Paragraph/AdSlot/AdSlot')
  ),
  ParagraphPartnerList: dynamic(
    () => import('../components/Paragraph/PartnerList/PartnerList')
  ),
};

export default PARAGRAPH_MAPPING;
