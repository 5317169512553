import { Box, BoxProps } from '@chakra-ui/react';

type ContainerProps = BoxProps & {
  small?: boolean;
  smaller?: boolean;
  smallest?: boolean;
  header?: boolean;
};

const Container: React.FC<ContainerProps> = ({
  children,
  small,
  smaller,
  smallest,
  header,
  ...props
}) => {
  let width = '1344px';

  if (small) {
    width = '960px';
  } else if (smaller) {
    width = '815px';
  } else if (smallest) {
    width = '450px';
  } else if (header) {
    width = '1542px';
  }

  return (
    <Box w="100%" maxW={width} m="0 auto" px={[4, 4, 4, 4, 0]} {...props}>
      {children}
    </Box>
  );
};

export default Container;
