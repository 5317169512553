import {
  GeocodeResult,
  GeocodeResultFeature,
  Maybe,
  TermCategory,
} from '../generated/types';
import queryString from 'query-string';
import { GeocoderFeature, NodeOrTeaserType } from '../types/global-types';
import {
  AutoCompleteTermType,
  TransformedAutocompleteData,
} from '../components/Common/BetterPlaceAutocomplete';
import { entityFilterConditionToQueryString } from './parseFilterQueryString';

export const parseSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.floor(seconds / 60) - hours * 60;

  return { hours, minutes };
};

export const padTime = (time: number) => (time < 10 ? `0${time}` : time);

export const secondsToHumanString = (seconds: number) => {
  const parsed = parseSeconds(seconds);

  return `${padTime(parsed.hours)}:${padTime(parsed.minutes)}`;
};

export const isMenuItemActive = (currentPath: string, menuPath: string) => {
  if (menuPath === '/') return menuPath === currentPath;

  return currentPath.startsWith(menuPath);
};

export const filterDuplicatedByEntityId = (
  itemsToFilter?: Maybe<NodeOrTeaserType>[] | NodeOrTeaserType[],
  itemsToMatchAgainst?: Maybe<NodeOrTeaserType>[] | NodeOrTeaserType[]
) => {
  return itemsToFilter?.filter((item: NodeOrTeaserType) => {
    const isReferenced = itemsToMatchAgainst?.find(
      (referencedPoi: NodeOrTeaserType) => {
        return referencedPoi.id === item.id;
      }
    );

    return !isReferenced;
  });
};

export const autocompleteTypeToFieldname = (type: string): string => {
  switch (type) {
    case 'cities':
      return 'city_ref_tid';
    case 'regions':
      return 'region_tid';
    case 'postcodes':
      return 'postcode_ref_tid';
    case 'countries':
      return 'country_tid';
    default:
      return '';
  }
};

export const autocompleteResultToUrl = (
  category: TermCategory,
  item?: TransformedAutocompleteData,
  radius?: string,
  additionalParams?: { [key: string]: string | string[] },
  isGroupSearch?: boolean,
  inputValue?: string
) => {
  const params: Record<string, string | string[] | number> = {};
  let onlyQuery = false;

  if (item) {
    if (item.type === 'geocode' && radius) {
      const data = item.data as GeocodeResultFeature | GeocoderFeature;
      params.query = item.name;
      params.latitude = parseFloat(data.geometry.coordinates[1] + '').toFixed(
        6
      );
      params.longitude = parseFloat(data.geometry.coordinates[0] + '').toFixed(
        6
      );
    } else {
      const field = autocompleteTypeToFieldname(item.type);
      const data = item.data as AutoCompleteTermType;
      const entityFilter = {
        field,
        operator: '=',
        value: data.id + '',
      };

      const filter = entityFilterConditionToQueryString(entityFilter);

      if (filter) {
        params.filter = filter;
      }
    }
  } else {
    onlyQuery = true;
    // if we dont have an item, user clicked search without autocomplete
    params.query = inputValue;
  }

  if (radius) {
    params.radius = radius;
  }

  if (additionalParams) {
    Object.keys(additionalParams).forEach((key) => {
      if (additionalParams[key]) {
        params[key] = additionalParams[key];
      }
    });
  }

  // Remove radius on only query
  if (onlyQuery && params.radius && !params.latitude && !params.longitude) {
    delete params.radius;
  }

  const stringifiedParams = queryString.stringify(params);
  const qs = stringifiedParams ? `?${stringifiedParams}` : '';

  if (isGroupSearch && category.groupSearchPageUrl) {
    return `${category.groupSearchPageUrl}${qs}`;
  }

  if (category.searchPageUrl) {
    return `${category.searchPageUrl}${qs}`;
  }

  return `/entdecken/${category.frontendUrl}${qs}`;
};

export const getSearchPath = (
  category: TermCategory,
  query: string,
  position: [number, number] | null,
  radius: string
) => {
  const params: Record<string, string | number> = {};

  if (query) {
    params.query = query;
  }

  if (position && radius) {
    params.latitude = parseFloat(position[1] + '').toFixed(6);
    params.longitude = parseFloat(position[0] + '').toFixed(6);
    params.radius = radius;
  }

  const stringifiedParams = queryString.stringify(params);
  const append = stringifiedParams ? `?${stringifiedParams}` : '';

  if (category.searchPageUrl) {
    return `${category.searchPageUrl}${append}`;
  }

  return `/entdecken/${category.frontendUrl}${append}`;
};

export const getQueryStringFromPath = (path: string) => {
  const index = path.indexOf('?');

  return index !== -1 ? path.substring(index + 1) : '';
};

export const getPathnameFromPath = (path: string) => {
  const index = path.indexOf('?');

  return index !== -1 ? path.substring(0, index) : path;
};

export const applyPageParamToRoute = (route: string, page: number) => {
  try {
    const parsedQuery = queryString.parse(getQueryStringFromPath(route), {
      sort: false,
    });

    delete parsedQuery.page;
    parsedQuery.page = page + '';

    const newQuery = queryString.stringify(parsedQuery, {
      sort: false,
    });

    const pathname = getPathnameFromPath(route);
    return newQuery ? `${pathname}?${newQuery}` : pathname;
  } catch (err) {
    console.error(err);
  }

  return route;
};

export const applyMapParamToRoute = (route: string, state?: boolean) => {
  try {
    const parsedQuery = queryString.parse(getQueryStringFromPath(route), {
      sort: false,
    });

    if (parsedQuery.page) {
      delete parsedQuery.page;
    }

    if (!state && parsedQuery.karte) {
      delete parsedQuery.karte;
    } else if (state) {
      parsedQuery.karte = '1';
    }
    const newQuery = queryString.stringify(parsedQuery, {
      sort: false,
    });

    const pathname = getPathnameFromPath(route);
    return newQuery ? `${pathname}?${newQuery}` : pathname;
  } catch (err) {
    console.error(err);
  }

  return route;
};

export const getZoomForRadius = (radius: number) => {
  switch (radius) {
    case 10000:
      return 11;

    case 20000:
      return 10;
    case 30000:
      return 10;
    case 50000:
      return 9;
    case 100000:
      return 8;
    case 200000:
      return 7;

    default:
      return 8;
  }
};

export const pluralize = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural;

export const getPercentage = (value: number, max: number) => {
  return (100 / max) * value;
};
